import PageEnum from '@/enums/pageEnum';
import OrderCardItem from '@/pages/order/index/components/OrderCardItem';
import RenderUtil from '@/utils/RenderUtil';
import { history } from '@@/core/history';
import { cn } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useTranslation } from 'react-i18next';

export default ({ item, itemIndex }: any) => {
  // 基础定义
  const { getSocketRowByOrderId, getSocketRowByName } = useModel('socket');
  const {
    currentUpClass,
    currentDownClass,
    currentUpBgClass,
    currentDownBgClass,
    reLoadOrder,
  } = useModel('system');
  //
  const { t } = useTranslation();

  // 订阅socket
  const orderSocket = getSocketRowByOrderId(item.id);
  const socketData = getSocketRowByName(item?.symbol??item?.paySymbolControl?.symbol);
  // 盈亏
  const profitPrice = item?.profitPrice;
  // 盈亏比
  const profitRate = item?.profitRate;

  //
  const priceAccuracy = item?.paySymbolControl?.priceAccuracy ?? orderSocket?.priceAccuracy ?? 8;
  const renderPositionItem = [
    {
      label: t('开仓价格'),
      value: RenderUtil.FormatAmount(item?.startPrice , 8, true),
    },
    {
      label: t('收益'),
      value: (
        <div
          className={`text-sm font-bold ${
            profitPrice > 0 ? currentUpClass : currentDownClass
          }`}
        >
          {profitPrice > 0 ? '+' : ''}
          {RenderUtil.FormatAmount(profitPrice,8,true)}
        </div>
      ),
    },
    {
      label: t('收益率'),
      value: (
        <div
          className={`text-sm font-bold ${
            profitRate > 0 ? currentUpClass : currentDownClass
          }`}
        >
          {profitRate > 0 ? '+' : ''}
          {/* {RenderUtil.formatAndCeilToTwoDecimals(profitRate*100)}% */}
          {RenderUtil.FormatAmount(profitRate*100, 2, false)}%
        </div>
      ),
    },
    {
      label: t('平仓价格'),
      value: RenderUtil.FormatAmount(item?.endPrice, 8, true),
    },
    {
      label: t('金额'),
      value: RenderUtil.FormatAmount(item?.inputPrice, 8,true),
    },
    // {
    //   label: t('当前价'),
    //   value: RenderUtil.FormatAmount(
    //     socketData?.a || orderSocket?.currentPrice,
    //     priceAccuracy,
    //     true,
    //   ),
    // },
    // {
    //   label: t('强平价格'),
    //   value: item?.forcedLiquidationPrice
    //     ? RenderUtil.FormatAmount(
    //         item?.forcedLiquidationPrice,
    //         orderSocket?.priceAccuracy ?? 2,
    //         false,
    //       )
    //     : '--',
    // },
    // {
    //   label: t('持仓/委托/可平(张)'),
    //   value: (
    //     <span>
    //       {RenderUtil.FormatAmount(item?.num, 0, false)}/
    //       {RenderUtil.FormatAmount(item?.entrustPositionNum, 0, false)}/
    //       {RenderUtil.FormatAmount(item?.availablePositionNum, 0, false)}
    //     </span>
    //   ),
    // },
  ];
  return (
    <div className='mt-3'>
      <OrderCardItem
        item={item}
        classNames={{
          base: '!mb-0',
          divider: '!hidden',
        }}
        itemIndex={itemIndex}
        infoArray={renderPositionItem}
        cardWrapper={'gridCol3'}
        title={
          <div className="flex justify-start flex-wrap items-center">
            <div className="text-titleColor font-[500] flex justify-center items-center">
              {item?.direction === 1 && (
                <span
                  className={cn(
                    'text-white font-normal ml-1 rounded-[4px] text-xs py-0.5 px-1',
                    currentUpBgClass,
                  )}
                >
                  {t('开多')}
                </span>
              )}
              {item?.direction !== 1 && (
                <span
                  className={cn(
                    'text-white font-normal bg-font-normal rounded-[4px] text-xs py-0.5 px-1',
                    currentDownBgClass,
                  )}
                >
                  {t('开空')}
                </span>
              )}
              <span className="ml-2 font-bold">{item?.paySymbolControl?.alias}</span>
            </div>
            {/* <span className="font-normal bg-backgroundAuxiliaryColor ml-1 rounded-[4px] p-1 text-xs">
              {t('永续')}
            </span> */}
            {/* {item?.earnestMode !== null && (
              <>
                <span className="font-normal bg-backgroundAuxiliaryColor ml-1 rounded-[4px] p-1 text-xs">
                  {item?.earnestMode === 1 ? t('全仓') : t('逐仓')}
                </span>
              </>
            )} */}
            {item?.lever !== null && (
              <span className="font-normal bg-backgroundAuxiliaryColor ml-1 rounded-[4px] p-1 text-xs">
                {orderSocket?.lever ?? item?.lever}x
              </span>
            )}
          </div>
        }
        onClickCard={() => {
          history.push(`${PageEnum.CONTRACT_ORDER_DETAIL}/${item?.id}`+'?&type=aiChildOrderDetail');
        }}
      />
    </div>
  );
};
