import { SvgSpinnersBarsRotateFade } from '@/assets/icons/comm/SvgSpinnersBarsRotateFade';
import BasePullDownToRefresh from '@/components/base/basePullDownToRefresh';
import PullToRefresh from '@/components/base/basePullToRefresh';
import BaseTopNav from '@/components/base/baseTopNav';
import { usePaginationPage } from '@/hooks/usePaginationPage';
import AiOrderDetailList from '@/pages/mine/aiChildrenOder/components/AiOrderDetailList';
import { aiPageSubApi } from '@/services/api/requestApi';
import useUrlState from '@ahooksjs/use-url-state';
import { cn } from '@nextui-org/react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  const [urlState] = useUrlState<any>({});
  const { id } = urlState || {};
  const [time, setTime] = useState([]);
  const [pageParams, setPageParams] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  // 请求参数(依赖更新,分页hooks会自动发起请求)
  const params = useMemo(() => {
    return {
      ...pageParams,
      orderId: id,
    };
  }, [id]);

  // 分页请求
  const {
    list,
    loading,
    loadMore,
    error,
    total,
    getList: fetchList,
    ...resetContractPageProps
  } = usePaginationPage({
    fn: aiPageSubApi,
    params,
    setParams: setPageParams,
  });
  return (
    <BasePullDownToRefresh
      refreshFn={async () => {
        await fetchList({ ...(params || {}), pageNo: 1 });
      }}
    >
      <div>
        <BaseTopNav
          title={t('量化交易详情')}
          rightNode={
            <>
              {loading && (
                <div
                  className={cn(
                    'flex items-center justify-center w-fit h-full mr-4',
                  )}
                >
                  <SvgSpinnersBarsRotateFade />
                </div>
              )}
            </>
          }
        />
        <div className="px-4">
          {/*列表*/}
          <div className="pt-[10px]">
            <PullToRefresh
              loadMore={loadMore}
              total={total}
              getList={fetchList}
              list={list}
              error={error}
              loading={loading}
              loadingDisplay={false}
              {...resetContractPageProps}
            >
              <AiOrderDetailList list={list} />
            </PullToRefresh>
          </div>
        </div>
        {/* 时间筛选器
        <FeaturesDateTime
          open={isOpen}
          onClose={onClose}
          onCancel={onClose}
          onConfirm={(value: any) => {
            const { start, end } = value;
            setTime([start, end] as any);
            onClose();
          }}
        /> */}
      </div>
    </BasePullDownToRefresh>
  );
};
