import AiOrderDetailCard from '@/pages/mine/aiChildrenOder/components/AiOrderDetailCard';
import { Divider } from '@nextui-org/react';

export default ({ list }: any) => {
  return (
    <>
      {/*列表*/}
      <div>
        {list?.map((item: any, itemIndex: number) => {
          return (
            <>
              <AiOrderDetailCard
                key={item?.id}
                item={item}
                itemIndex={itemIndex}
              />
              <Divider className="my-4 !bg-backgroundAuxiliaryColor" />
            </>
          );
        })}
      </div>
    </>
  );
};
